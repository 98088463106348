import React, { useContext } from "react";
import placeholder from "../static/voucher/voucher.png";
import { AppContext } from "../context";
import { useStripeCheckout } from "../hooks/useStripeCheckout";
import Layout from "../components/layout";
import { PageTitle } from "../components/Common/PageTitle";

const CheckoutButton = ({
  product,
  text = "Continue to payment",
  amount = 5000,
}) => {
  const { loading, redirectToCheckout } = useStripeCheckout(product, {
    isGiftCard: true,
    giftCardAmount: amount,
  });

  return (
    <span className="inline-flex rounded-md shadow-sm items-center">
      <button
        type="button"
        onClick={redirectToCheckout}
        className="px-12 py-6 mx-auto text-base font-bold text-blue-700 hover:text-blue-800 sm:text-lg cursor-pointer
                  md:text-inline-flex items-center justify-center  border border-transparent rounded-md bg-gray-50 hover:bg-blue-100"
      >
        {loading ? (
          <div className="flex flex-row">
            <svg
              className="animate-spin w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                clipRule="evenodd"
              />
            </svg>
            &nbsp; Creating Order
          </div>
        ) : (
          text
        )}
      </button>
    </span>
  );
};

const GiftCardSalesPage = () => {
  const { state: contextState } = useContext(AppContext);
  const products = contextState ? contextState.products : [];
  console.log(contextState.products);
  const giftCardProduct = products.filter((it) => {
    return it.metadata.type === "gift_card";
  });

  console.log(giftCardProduct[0]);

  return (
    <Layout>
      <div className="bg-white mb-12">
        <div className="m-12">
          <PageTitle title="Gift Voucher" />
        </div>
        <main>
          <div className="hidden sm:max-w-3xl sm:mx-auto sm:p-6 sm:block lg:p-8">
            <div className="relative shadow-xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  src={placeholder}
                  alt="Gift Voucher"
                  className="h-full w-full object-cover"
                />

                <div className="absolute inset-0 mix-blend-multiply"></div>
              </div>
              <div className="relative px-4 py-64 sm:px-6 sm:p-24 lg:p-32 lg:px-8">
                <div className="py-16">
                  <div className="mt-16  mx-12">
                    <div className=" text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:hidden mx-auto sm:p-6">
            <img
              src={placeholder}
              alt="Gift Voucher"
              className="h-full w-full object-cover"
            />
          </div>

          <div className="bg-blue-600 md:mt-4">
            <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
              <p className="text-xl text-white tracking-wide text-center whitespace-pre-wrap">
                Know someone that would love to take a class, but you’re not
                sure what class that is?
              </p>
              <br />
              <p className="text-xl text-white tracking-wide text-center whitespace-pre-wrap">
                Why not give them a gift voucher!
              </p>
              <br />
              <p className="text-xl text-white tracking-wide text-center whitespace-pre-wrap">
                The price for a regular class is €50, but email me if you want a
                gift voucher with a different amount.
              </p>
              <br />
              <p className="text-xl text-white tracking-wide text-center whitespace-pre-wrap">
                You receive purchase confirmation immediately. Please give me 24
                hours to send you a PDF version of the gift voucher with all the
                information.
              </p>
              <div className="lg:flex-shrink-0 mx-12 my-8">
                <div className="rounded-md text-center">
                  <CheckoutButton
                    product={giftCardProduct[0]}
                    text="Buy Gift Voucher €50"
                    amount={5000}
                  />
                </div>
              </div>

              <p className="text-xl text-white tracking-wide text-center whitespace-pre-wrap">
                How to redeem a gift voucher:
              </p>
              <br />
              <p className="text-xl text-white tracking-wide text-center whitespace-pre-wrap">
                Send me an email with your email address, voucher number, and
                the class you want to take. That way I can set up your account
                for you.
              </p>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default GiftCardSalesPage;
